import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import Translate, { translate, translateWithNumber } from "../../AppContext/Translate";
import PlanItem from "../PlanItem";
import PostMessage from "../../Components/PostMessage";
import StartDates from "../StartDates";
import Add2Cart from "../../AppContext/Add2Cart";
import { RiGroup2Fill } from "react-icons/ri";

function GeneralPackage({ index, data, student, options, numDays }) {
    const { dicLang } = useContext(AppContext);
    const [startDates, setStartDates] = useState(null);
    const [groupId, setGroupId] = useState(-1);

    useEffect(() => {
        if (data.plan === 1) {
            getAPICall('group/start', { courseId: data.course }, (datag) => {
                if (datag) {
                    var dates = [];
                    for (var i = 0; i < datag.length; i++) {
                        const row = datag[i];
                        if (row.elapsedDays < row.numDays - 1) {
                            dates.unshift(row);
                        } else break;
                    }
                    setStartDates(dates);
                    if (dates.length > 0) setGroupId(dates[0].id);
                }
            });
        }
    }, [data.course, data.plan]);

    const months = Math.round(data.daysToExpire / 3) / 10;
    const expiration = data.daysToExpire >= 365000 ? translate("Постійний доступ до всіх уроків і матеріалів курсу", dicLang)
        : translateWithNumber("Доступ до всіх уроків і матеріалів курсу протягом * місяців", months, dicLang);

    return <div style={{ width: "360px", maxWidth: "100%", margin: "16px" }}>
        <div className="center" style={{ zIndex: 100 }}>
            <img alt="" src="/bkg/package.png" style={{ width: "64px" }} />
        </div>
        <div style={{ marginTop: "-28px", color: "black" }}>
            <img alt="" src="/bkg/decor/top.png" style={{ width: "100%", display: "block" }} />
            <div style={{ background: "white" }} className="pad12">
                <p style={{ paddingTop: "24px", paddingBottom: "16px" }} className="bold center xlarge">{data.title}</p>
                <PlanItem text={expiration} desc={data.daysToExpire >= 365000 ? null :
                    translateWithNumber("Гарантований доступ * днів", data.daysToExpire, dicLang)} />
                <PlanItem text={data.plan === 0 ? translate("Навчайтеся у своєму темпі", dicLang) :
                    translateWithNumber("Програма курсу розрахована на * день", numDays, dicLang)} />
                {data.plan === 1 && <PlanItem text="Творча спільнота" translateText={true} translateDesc={true}
                    desc="Доступ до чату для обговорення матеріалів курсу з однокурсниками та викладачами" />}
                {data.options.includes('Y') && <PlanItem text="Отримуйте відгуки від викладачів щодо ваших завдань" translateText={true} />}
                {data.options.includes('Z') && <PlanItem text="Підсумковий проект курсу" translateText={true} />}
                {data.options.includes('Z') && <PlanItem text="Диплом" translateText={true} />}
                {data.options.split('').map((c, index) => (
                    c !== 'Y' && c !== 'Z' && c in options &&
                    <PlanItem key={index} text={options[c].title}
                        desc={options[c].description && options[c].description.length > 0 ?
                            <PostMessage msg={options[c].description} sep={false} open={false} textStyle="smallTextPost" /> : null} />
                ))}
                <p style={{
                    textAlign: "center", fontSize: "large", fontWeight: "600", fontFamily: "Mak",
                    marginTop: "16px"
                }}>{data.price} ГРН.</p>
                {data.plan === 1 && startDates && <>
                    <hr />
                    <p style={{ fontStyle: "italic" }}><RiGroup2Fill size={20} style={{ marginRight: "6px" }}
                        color="darkgray" /><Translate>Групи за розкладом</Translate></p>
                    <StartDates dates={startDates} setGroupId={setGroupId} groupId={groupId} pkgId={data.id} />
                </>}
                {(data.plan === 0 || groupId >= 0) &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                        <Add2Cart data={{ kind: "plan", packageId: data.id, courseId: data.course, groupId: groupId }}
                            student={student} text={translate("активна кнопка", dicLang)} icon={null}
                            buttonStyle={{backgroundColor: "#ff2f43", color: "white", padding: "6px 16px",
                                borderRadius: "16px", cursor: "pointer"}} />
                    </div>}
            </div>
            <img alt="" src="/bkg/decor/bottom.png" style={{ width: "100%", display: "block" }} />
        </div>
    </div>
}

/*
Постійний доступ до всіх уроків і матеріалів курсу.
Програма курсу розрахована на 7 день Творча спільнота.
Отримуйте відгуки від викладачів щодо ваших завдан.
Підсумковий проект курсу.
Диплом Autógrafo*/

/*Permanent access to all lessons and course materials.
The course program is designed for the 7th day Creative community.
Get feedback from teachers on your assignments.
Final project of the course.
Diploma Autografo
*/

// {"id":3,"course":1,"plan":0,"title":"ПАКЕТ БАЗОВИЙ","options":"E","price":5500,"active":1,"daysToExpire":90,"diploma":0}


export default GeneralPackage