import { useState, useContext, useEffect, lazy, Suspense } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Translate, { translate } from '../../AppContext/Translate';
import { PiPresentationChartDuotone } from "react-icons/pi";
import { HiPhotograph } from 'react-icons/hi';
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { AppContext } from '../../AppContext/AppContext';
import { MediaPath } from '../../Components/MediaPath';
import WriteHelper from '../../Components/WriteHelper';
import { postAPICall, postAPIUploadProgress } from '../../Components/APICall';
import UploadProgress from '../../Components/UploadProgress';
import SelectLang from './Info/SelectLang';
import SelectCategory from './Info/SelectCategory';
import UploadVideo from './Info/UploadVideo';
import ColorTheme from './Info/ColorTheme';
import BackgroundImageSelector from '../../Course/Themes/BackgroundImageSelector';
import ForegroundImageSelector from '../../Course/Themes/ForegroundImageSelector';
import RTItemsEditor from './Info/RTItemsEditor';
import CourseIdeas from './Info/CourseIdeas';

const UploadPicture = lazy(() => import('../../Components/UploadPicture'));

function CabEditCourseInfo({get, set, courseData, categories, onToggle, activeKey}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [picNumber, setPicNumber] = useState(1);
    const [showChangePicture, setShowChangePicture] = useState(false);
    const [format, setFormat] = useState({colorTheme: 0, backImage: 0, frame: 0}); 
    const [presentationVideo, setPresentationVideo] = useState(null);
    const [description, setDescription] = useState([]);
    const [goals, setGoals] = useState([]);
    const [audience, setAudience] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [ideas, setIdeas] = useState([]);
    const [diplomaTitles, setDiplomaTitles] = useState({});
    const [changes, setChanges] = useState(false);
    const [progress, setProgress] = useState(null);

    useEffect(() => {
        const tokens = courseData.content.split('|').map(Number);
        if (tokens.length !== 4) {
            setFormat({ colorTheme: 0, backImage: 0, frame: 0 });
            setPresentationVideo(null);
        } else {
            const presentationVideo = isNaN(tokens[0]) ? -1 : tokens[0];
            const colorTheme = isNaN(tokens[1]) ? 0 : tokens[1];
            const backImage = isNaN(tokens[2]) ? 0 : tokens[2];
            const frame = isNaN(tokens[3]) ? 0 : tokens[3];
            setFormat({ colorTheme: colorTheme, backImage: backImage, frame: frame });
            setPresentationVideo(presentationVideo < 0 ? null : {kind: "cloud", id: presentationVideo,
                action: "none"});
        }
    }, [userId, token, courseData.id, courseData.content]);

    useEffect(() => {
        async function parseTokens(tokens) {
            var desc = [];
            for (var i = 0; i < tokens.length; i++) {
                const msg = await WriteHelper.parseMessage(tokens[i], null, userId, token);
                if (msg.text.length > 0) desc.push(msg.text);
            }
            setDescription(desc);
        }
        const tokens = courseData.description ? courseData.description.split('§') : [];
        parseTokens(tokens);
    }, [courseData.description, courseData.id, userId, token]);

    useEffect(() => {
        async function parseTokens(tokens) {
            var desc = [];
            for (var i = 0; i < tokens.length; i++) {
                const msg = await WriteHelper.parseMessage(tokens[i], null, userId, token);
                if (msg.text.length > 0) desc.push(msg.text);
            }
            setGoals(desc);
        }
        const tokens = courseData.goals ? courseData.goals.split('§') : [];
        parseTokens(tokens);
    }, [courseData.goals, courseData.id, userId, token]);

    useEffect(() => {
        async function parseTokens(tokens) {
            var desc = [];
            for (var i = 0; i < tokens.length; i++) {
                const msg = await WriteHelper.parseMessage(tokens[i], null, userId, token);
                if (msg.text.length > 0) desc.push(msg.text);
            }
            setAudience(desc);
        }
        const tokens = courseData.audience ? courseData.audience.split('§') : [];
        parseTokens(tokens);
    }, [courseData.audience, courseData.id, userId, token]);

    useEffect(() => {
        async function parseTokens(tokens) {
            var desc = [];
            for (var i = 0; i < tokens.length; i++) {
                const msg = await WriteHelper.parseMessage(tokens[i], null, userId, token);
                if (msg.text.length > 0) desc.push(msg.text);
            }
            setRequirements(desc);
        }
        const tokens = courseData.requirements ? courseData.requirements.split('§') : [];
        parseTokens(tokens);
    }, [courseData.requirements, courseData.id, userId, token]);

    useEffect(() => {
        var videas = [];
        var tokens = courseData.ideas.split('|');
        for (var i = 0; i < tokens.length - 1; i += 2) {
            videas.push({icon: tokens[i], idea: tokens[i+1]});
        }
        setIdeas(videas);
    }, [userId, token, courseData.id, courseData.ideas]);

    useEffect(() => {
        var newTitles = {};
        const titles = courseData.diplomaTitles.split("|");
        for (var i = 0; i < titles.length; i++) {
            if (titles[i].length > 3)
                newTitles[titles[i].substring(0, 2)] = titles[i].substring(3);
        }
        setDiplomaTitles(newTitles);
    }, [userId, token, courseData.id, courseData.diplomaTitles]);

    const saveError = (msg = "не вдалося зберегти зміни") => {
        setProgress(null);
        alert(translate("Помилка", dicLang) + ": " + translate(msg, dicLang));
    }

    const buildItems = (items) => {
        var desc = "";
        for (var i = 0; i < items.length; i++) {
            if (i > 0) desc += '§';
            desc += WriteHelper.encode(WriteHelper.filterSimpleText(items[i]));;
        }
        return desc;
    }

    const saveData = (videoId) => {
        var parsedIdeas = "";
        for (var i = 0; i < ideas.length; i++) {
            const idea = ideas[i];
            if (i > 0) parsedIdeas += '|';
            parsedIdeas += idea.icon + '|' + WriteHelper.filterSimpleText(idea.idea);
        }
        var content = videoId + "|" + format.colorTheme + "|" + format.backImage + "|" + format.frame;
        var titles = '';
        ['en', 'ua'].forEach((language) => { 
            if (titles.length > 0) titles += '|';
            titles += language + '-' + WriteHelper.filterSimpleText(getDiplomaTitle(language)); });
        postAPICall('course/info', {userId: userId, token: token, course: courseData.id,
            title: WriteHelper.filterSimpleText(courseData.title), lang: courseData.lang,
            category: courseData.category, ideas: parsedIdeas,
            mission: WriteHelper.filterSimpleText(courseData.mission),
            content: content, diplomaTitles: titles,
            shortDescription: WriteHelper.filterSimpleText(courseData.shortDescription),
            description: buildItems(description), goals: buildItems(goals),
            audience: buildItems(audience), requirements: buildItems(requirements)},
            (data) => {
                if (data && "error" in data && data.error === "none") {
                    set(courseData.id, 'description', buildItems(description));
                    set(courseData.id, 'goals', buildItems(goals));
                    set(courseData.id, 'audience', buildItems(audience));
                    set(courseData.id, 'requirements', buildItems(requirements));
                    set(courseData.id, 'ideas', parsedIdeas);
                    set(courseData.id, 'content', content);
                    set(courseData.id, 'diplomaTitles', titles);
                    setProgress(null);
                    setChanges(false);
                    alert(translate("Зміни успішно збережено", dicLang));
                } else saveError();
            }, saveError);
    }

    const sectionError = (section, msg) => {
        alert(translate("Помилка в розділі", dicLang) + ' "' + translate(section, dicLang) +
            '": ' + translate(msg, dicLang) + ".");
        return false;
    }

    const countLines = (text) => {
        const regexBr = /<br\s*\/?>/gi;
        const n = text.match(regexBr);
        return n ? n.length : 0;
    };

    const checkItems = (items, section, maxLength=2000) => {
        if (items.length < 1) return sectionError(section, "немає предметів");
        var length = 0;
        for (var i = 0; i < items.length; i++) {
            const item = items[i];
            length += item.length;
            if (length > maxLength) return sectionError(section, "загальна довжина тексту елементів задовга");
            if (countLines(item) > 1) return sectionError(section, "кожен блок повинен мати не більше двох абзаців");
            if (item.length < 1) return sectionError(section, "є порожні елементи");
        }
        return true;
    }

    const checkIdeas = (ideas) => {
        var parsedIdeas = "";
        if (ideas.length < 0) {
            saveError("ви повинні визначити принаймні одну головну ідею");
            return false;
        }
        for (var i = 0; i < ideas.length; i++) {
            const idea = ideas[i];
            if (idea.icon < 1) {
                saveError("є ідеї без відповідного значка")
                return false;
            }
            if (idea.idea.length < 1) {
                saveError("є порожні ідеї")
                return false;
            }
            if (i > 0) parsedIdeas += '|';
            parsedIdeas += idea.icon + '|' + WriteHelper.filterSimpleText(idea.idea);
        }
        if (parsedIdeas.maxLength > 1024) {
            saveError("текст ідей задовгий");
            return false;
        }
        return true;
    }

    const doCheckings = () => {
        if (!checkItems(description, "ПРО ЩО?")) return false;
        if (!checkItems(goals, "ВПІЗНАЛИ СЕБЕ?")) return false;
        if (!checkItems(audience, "ЦЕЙ КУРС ДЛЯ ВАС, ЯКЩО ВИ")) return false;
        if (!checkItems(audience, "ВАМ ЗНАДОБИТЬСЯ")) return false;
        if (!checkIdeas(ideas)) return false;
        if (get('mission', '') < 1) {
            saveError("слоган порожній");
            return false;
        }
        ['en', 'ua'].forEach((language) => {
            const title = getDiplomaTitle(language);
            if (title.length < 1) {
                saveError("необхідно визначити назву курсу для диплома всіма мовами");
                return false;
            }
        });
        return true;
    }

    const save = () => {
        if (!doCheckings()) return;
        var path = new MediaPath(MediaPath.kind_openMediaCourse);
        path.add(courseData.id);
        path.addStr('desc');        
        if (presentationVideo) {
            if (presentationVideo.action === "none") {
                saveData("id" in presentationVideo ? presentationVideo.id : -1);
            } else if (presentationVideo.action === "upload") {
                setProgress({file: presentationVideo.file.name, percent: 0, action: 'upl'});
                postAPIUploadProgress('cabinet/upload', {name: presentationVideo.file.name,
                    buffer: presentationVideo.file.source.buffer},
                    {userId: userId, token: token, path: path.jsonPath, toProcess: true},
                    (data) => {
                        setPresentationVideo({kind: "cloud", id: data.id, action: "none"});
                        saveData(data.id);
                    },
                    (data) => { 
                        saveError();
                    },
                    (percent) => { setProgress({file: presentationVideo.file.name, percent: percent, action: 'upl'});
                });
            } else {
                postAPICall('cabinet/delete', {userId: userId, token: token, path: path,
                    videoId: presentationVideo.id}, () => {
                    setPresentationVideo(null);
                    saveData(-1);
                }, saveError);
            }
        } else saveData(-1); 
    }

    const setColorTheme = (theme) => {
        setFormat({...format, colorTheme: theme});
    }

    const setBackImage = (n) => {
        setFormat({...format, backImage: n});
    }

    const setFrame = (n) => {
        setFormat({...format, frame: n});
    }

    const getDiplomaTitle = (language) => {
        if (language in diplomaTitles) return diplomaTitles[language];
        return "";
    }

    const updateDiplomaTitle = (title, language) => {
        const newTitles = {...diplomaTitles};
        newTitles[language] = title;
        setDiplomaTitles(newTitles);
        set(null, null, null);
        setChanges(true);
    }

    return <Accordion.Item eventKey="1">
        <Accordion.Header onClick={() => onToggle("1")}>
            <PiPresentationChartDuotone size="24" style={{margin: "1px 8px 0px 0px"}}/>
            <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Інформація про курс</Translate></span>
        </Accordion.Header>
        <Accordion.Body>
            {activeKey === "1"  && <>
                <Form.Label><Translate>Назва курсу</Translate></Form.Label>
                <Form.Control value={get('title', '')} type="text" maxLength={96} 
                    onChange={(e) => { set(courseData.id, 'title', e.target.value); setChanges(true); }}/>
                <div style={{textAlign: "center"}}>
                    <figure>
                        <img alt="" src={"/courses/cover" + get('id') + ".jpg?n=" + picNumber} width="348px" 
                            style={{borderStyle: "ridge", maxWidth: "100%"}}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/courses/nocover.jpg";
                            }} />
                    </figure>
                    <Button variant="secondary" size="sm" onClick={()=>{ setShowChangePicture(true); }}>
                        <HiPhotograph size="18" style={{marginRight: "4px", marginTop: "-2px"}}/>
                        <Translate>Змінити покриття курсу</Translate>
                    </Button>
                    <Suspense>
                        <UploadPicture show={showChangePicture} handleClose={()=>{ setShowChangePicture(false); }}
                            width={348} height={196} title="Фото на обкладинці" apiFnc="course/photo"
                            forceUpdate={() => {
                                setPicNumber(current => current + 1);
                            }} params={{course: get('id')}}/>
                    </Suspense>
                </div>
                <Form.Label><Translate>Короткий опис</Translate></Form.Label>
                <Form.Control value={courseData.shortDescription ? courseData.shortDescription : ""}
                    type="text" as="textarea" maxLength={255} id="inputSdesc" rows="3"
                    onChange={(e) => { 
                    set(courseData.id, 'shortDescription', e.target.value);
                    setChanges(true); 
                }} />
                <hr/>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    <div>
                        <SelectLang get={get} set={set} courseId={courseData.id} setChanges={setChanges}/>
                        <SelectCategory courseId={courseData.id} categories={categories} category={courseData.category}
                            set={set} setChanges={setChanges}/>
                    </div>
                    <div style={{minWidth: "320px", display: "flex"}}>
                        <UploadVideo selectedVideo={presentationVideo} setSelectedVideo={setPresentationVideo}
                            dicLang={dicLang} setChanges={setChanges}/>
                    </div>
                </div>
                <hr/>
                <ColorTheme theme={format.colorTheme} setTheme={setColorTheme} setChanges={setChanges} dicLang={dicLang}/>
                <BackgroundImageSelector selectedImage={format.backImage} setSelectedImage={setBackImage}
                    colorTheme={format.colorTheme} setChanges={setChanges}/>
                <ForegroundImageSelector selectedImage={format.frame}  setSelectedImage={setFrame}
                    category={courseData.category} colorTheme={format.colorTheme} setChanges={setChanges}/>
                <hr/>
                <RTItemsEditor title={<Form.Label className='bold'><Translate>ПРО ЩО?</Translate></Form.Label>}
                    items={description} setItems={setDescription} setChanges={setChanges}/>
                <hr/>
                <RTItemsEditor title={<Form.Label className='bold'><Translate>ВПІЗНАЛИ СЕБЕ?</Translate></Form.Label>}
                    items={goals} setItems={setGoals} setChanges={setChanges}/>
                <hr/>
                <RTItemsEditor title={<Form.Label className='bold'><Translate>ЦЕЙ КУРС ДЛЯ ВАС, ЯКЩО ВИ</Translate>:</Form.Label>}
                    items={audience} setItems={setAudience} setChanges={setChanges}/>
                <hr/>
                <RTItemsEditor title={<Form.Label className='bold'><Translate>ВАМ ЗНАДОБИТЬСЯ</Translate>:</Form.Label>}
                    items={requirements} setItems={setRequirements} setChanges={setChanges}/>
                <hr/>
                <Form.Label className='bold'><Translate>Ключові ідеї</Translate></Form.Label>
                <CourseIdeas ideas={ideas} setIdeas={setIdeas} setChanges={setChanges}/>
                <hr/>
                <Form.Label className='bold'><Translate>Слоган (головна ідея)</Translate></Form.Label>
                <Form.Control value={get('mission', '')} type="text" id="inputSlogan" onChange={(e) => { 
                    set(courseData.id, 'mission', e.target.value);
                    setChanges(true); }} />
                <hr/>
                <Form.Label className='bold'><Translate>Назва курсу для диплома</Translate></Form.Label>
                {['en', 'ua'].map((language, i) => {
                    return <div key={i} style={{display: "flex", alignItems: "center", marginBottom: "4px"}}>
                        <img src={"/lang/" + language + ".png"} alt={language} style={{height: "24px", marginRight: "8px"}}/>
                        <Form.Control value={getDiplomaTitle(language)} type="text" id={"inputDiplom" + i}
                            style={{margin: "0px"}} onChange={(e) => { updateDiplomaTitle(e.target.value, language); }}/>
                    </div>
                })}
            </>}
            <div style={{textAlign: "center", marginTop: "16px"}}>
                <Button disabled={!changes} onClick={save}><Translate>Зберегти зміни</Translate></Button>
            </div>
            <UploadProgress progress={progress}/>
        </Accordion.Body>
    </Accordion.Item>
}

export default CabEditCourseInfo