import { useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from "react";
import { getAPICall } from "../Components/APICall";
import { useWindowSize } from "../AppContext/WindowSize";
import GeneralCover from "./General/GeneralCover";
import GeneralTitle from "./General/GeneralTitle";
import VideoPreview from "../Components/VideoPreview";
import GeneralAbout from "./General/GeneralAbout";
import WriteHelper from "../Components/WriteHelper";
import GeneralGoals from "./General/GeneralGoals";
import GeneralMatter from "./General/GeneralMatter";
import GeneralAudienceCover from "./General/GeneralAudienceCover";
import { getTitleBackgroundColor } from "./Themes/ThemeColors";
import GeneralAudience from "./General/GeneralAudience";
import GeneralReqCover from "./General/GeneralReqCover";
import GeneralReq from "./General/GeneralReq";
import GeneralTeam from "./General/GeneralTeam";
import GeneralPackages from "./General/GeneralPackages";
import CourseOpinions from "./CourseOpinions";

function processLines(items) {
    var tokens = items ? items.split('§') : [];
    var result = [];
    for (var i = 0; i < tokens.length; i++) {
        const item = tokens[i];
        if (item.trim().length > 0) {
            const elements = WriteHelper.parseElements(item);
            result.push(elements);
        }
    }
    return result;
}

function GeneralCourseInfo({courseId, student, info, goToPlans}) {
    const [width,] = useWindowSize();
    const [video, setVideo] = useState(null);
    const [numUnits, setNumUnits] = useState(0);
    const [numVideoMaterials, setNumVideoMaterials] = useState(0);
    const [numTasks, setNumTasks] = useState(0);
    const [description, setDescription] = useState([]);
    const [goals, setGoals] = useState([]);
    const [audience, setAudience] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [packages, setPackages] = useState([]);
    const [options, setOptions] = useState({});
    const plansRef = useRef(null);
    let navigate = useNavigate(); 

    useEffect(() => {
        getAPICall('course/packages', {courseId: courseId, order: 1}, (data) => {
            if (data && data.length > 0) setPackages(data);
        });
        getAPICall('course/options', {course: courseId}, async (data) => {
            if (data) {
                var opt = {};
                for (var i = 0; i < data.length; i++) {
                    const row = data[i];
                    opt[row.code] = row;
                }
                setOptions(opt);
            }
        });
        getAPICall('units/count', {course: courseId}, (data) => {
            if (data && 'num' in data) setNumUnits(data.num);
        });
        getAPICall('materials/video', {course: courseId}, (data) => {
            if (data && 'num' in data) setNumVideoMaterials(data.num);
        });
        getAPICall('materials/numTasks', {course: courseId}, (data) => {
            if (data && 'num' in data) setNumTasks(data.num);
        });
    }, [courseId]);

    useEffect(() => {
        if (goToPlans && plansRef.current && packages.length > 0) {
            plansRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            const checkPosition = () => {
                const rect = plansRef.current.getBoundingClientRect();
                if (rect.top !== 0) {
                    plansRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            };
            setTimeout(checkPosition, 300);
        }
    }, [goToPlans, packages, plansRef]);

    useEffect(() => {
        const parseElements = () => {
            setDescription(processLines(info.description));
            setGoals(processLines(info.goals));
            setAudience(processLines(info.audience));
            setRequirements(processLines(info.requirements));
        }
        parseElements();
    }, [info]);

    const routeChange = (path) => { 
        navigate(path);
    }

    var format = { presentationVideo: -1, colorTheme: 0, backImage: 0, frame: 0 };
    if (info && info.content) {
        const tokens = info.content.split('|').map(Number);
        if (tokens.length === 4) {
            format.presentationVideo = isNaN(tokens[0]) ? -1 : tokens[0];
            format.colorTheme = isNaN(tokens[1]) ? 0 : tokens[1];
            format.backImage = isNaN(tokens[2]) ? 0 : tokens[2];
            format.frame = isNaN(tokens[3]) ? 0 : tokens[3];
        }
    }

    return <div style={{backgroundColor: "white"}}>
        {video && <VideoPreview video={video.id} setVideo={setVideo} title={video.title}/>}
        <div style={{display: "flex", flexWrap: "wrap"}}>
            <GeneralCover width={width} backImage={format.backImage} theme={format.colorTheme}
                frame={format.frame} category={info.category}/>
            <GeneralTitle width={width} theme={format.colorTheme} title={info.title} slogan={info.mission}
                ideas={info.ideas} plansRef={plansRef} setVideo={setVideo}
                presentationVideo={format.presentationVideo}/>
        </div>
        <GeneralAbout theme={format.colorTheme} lines={description}/>
        <GeneralGoals theme={format.colorTheme} lines={goals} width={width} backImage={format.backImage}
            plansRef={plansRef}/>
        <GeneralMatter numUnits={numUnits} numVideoMaterials={numVideoMaterials} numTasks={numTasks}
            theme={format.colorTheme}/>
        <div style={{display: "flex", flexWrap: "wrap", backgroundColor: getTitleBackgroundColor(format.colorTheme)}}>
            <GeneralAudienceCover width={width} backImage={format.backImage} theme={format.colorTheme}
                category={info.category}/>
            <GeneralAudience width={width} theme={format.colorTheme} lines={audience} plansRef={plansRef}/>
        </div>
        <div style={{display: "flex", flexWrap: "wrap", backgroundColor: "white"}}>
            <GeneralReqCover width={width}/>
            <GeneralReq width={width} theme={format.colorTheme} lines={requirements}/>
        </div>
        <GeneralTeam course={courseId} routeChange={routeChange} theme={format.colorTheme}/>
        <GeneralPackages theme={format.colorTheme} packages={packages} student={student}
            options={options} numDays={info.numDays} plansRef={plansRef}/>
        <CourseOpinions courseId={courseId} theme={format.colorTheme} width={width} plansRef={plansRef}/>
    </div>
}

export default GeneralCourseInfo